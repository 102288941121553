import styled, { css } from "styled-components";

import { InputStatus, STATUS_TO_COLOR } from "./utils";

type Props = {
    status?: InputStatus;
};

const InputBase = styled.input<Props>`
    ${({ theme, status, disabled }) => {
        const hasDefaultStatus = status === InputStatus.DEFAULT;

        return css`
            width: 100%;
            min-height: 32px;
            background-color: ${theme.palette.grey.lightest};
            border: ${hasDefaultStatus ? 1 : 2}px solid ${status && theme.palette[STATUS_TO_COLOR[status]].base};
            margin: ${({ theme }) => theme.spacing[2]} 0;
            border-radius: ${({ theme }) => theme.shape.borderRadius.medium};
            padding: ${hasDefaultStatus ? 9 : 8}px;

            ${theme.typography.p}
            font-weight: 600;

            &:focus {
                outline: ${({ theme }) => theme.palette.blue.focus} solid 3px;
                outline-offset: 2px;
            }

            &::placeholder {
                color: ${theme.palette.grey.base};
            }

            ${disabled && { opacity: 0.5 }}
        `;
    }}
`;

export default InputBase;
