import React from "react";

import SvgIcon from "./SvgIcon";
import { IconBaseProps } from "./types";

// TODO: use new design system 24px Icons
const ChevronDown = ({ className }: IconBaseProps) => (
    <SvgIcon
        className={className}
        width="14"
        height="8"
        viewBox="0 0 14 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        focusable="false"
    >
        <path
            d="M1 1L7 7L13 1"
            stroke="#000000"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="colorStroke"
        />
    </SvgIcon>
);

export default ChevronDown;
