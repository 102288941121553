import React, { FC } from "react";
import styled, { css } from "styled-components";

import { CircleCheck, Error, InfoIcon as Info } from "../icons";
import Text from "../Text";
import { InputStatus, STATUS_TO_COLOR } from "./utils";

const baseIconStyle = css`
    width: 16px;
    height: 16px;
`;

const ErrorIcon = styled(Error)`
    ${baseIconStyle}
`;

const SuccessIcon = styled(CircleCheck)`
    ${baseIconStyle}

    .colorFill {
        fill: ${({ theme }) => theme.palette.green.base};
    }

    .colorStroke {
        stroke: ${({ theme }) => theme.palette.white.base};
    }
`;

const InfoIcon = styled(Info)`
    ${baseIconStyle}

    .colorFill {
        fill: ${({ theme }) => theme.palette.purple.lightest};
    }

    .colorStroke {
        stroke: ${({ theme }) => theme.palette.purple.base};
    }
`;

const FeedbackText = styled(Text).attrs({ variant: "subheading" })<{ status: InputStatus }>`
    color: ${({ status, theme }) => theme.palette[STATUS_TO_COLOR[status]].base};
`;

const statusIcon = {
    [InputStatus.SUCCESS]: <SuccessIcon />,
    [InputStatus.ERROR]: <ErrorIcon />,
    [InputStatus.INFO]: <InfoIcon />,
    [InputStatus.DEFAULT]: null,
};

type Props = {
    className?: string;
    status: InputStatus;
    fieldName: string;
};

const Feedback: FC<Props> = ({ status, fieldName, children, className }) => {
    return (
        <div className={className}>
            {statusIcon[status]}
            <FeedbackText id={fieldName} data-cy="feedback-message" status={status}>
                {children}
            </FeedbackText>
        </div>
    );
};

export default styled(Feedback)<{ status: InputStatus }>`
    display: flex;
    align-items: center;

    ${FeedbackText} {
        ${({ status, theme }) => status !== InputStatus.DEFAULT && `margin-left: ${theme.spacing[3]};`};
    }
`;
