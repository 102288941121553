import styled from "styled-components";

import Text from "../Text";

const HelperText = styled(Text)`
    color: ${({ theme }) => theme.palette.grey.base};
    font-size: 10px;
    font-weight: 600;
`;

export default HelperText;
