import { useOverlayTriggerState, OverlayTriggerProps } from "@react-stately/overlays";
import React, { MutableRefObject, useEffect, useState } from "react";
import { useOverlayTrigger } from "react-aria";

import { PopupDialog } from "./shared/PopupDialog";
import { PopupOverlay } from "./shared/PopupOverlay";

export enum PopupMessageType {
    INFO = "INFO",
    ERROR = "ERROR",
}

export enum PopupType {
    CONFIRM = "CONFIRM",
    ALERT = "ALERT",
}

export type Props = {
    label: string;
    open: boolean;
    popupType: PopupType;
    messageType: PopupMessageType;
    onClickCancel?: () => void;
    onClickConfirm?: () => void;
    onClickOk?: () => void;
    isConfirmLoading?: boolean;
    cancelText?: string;
    confirmText?: string;
    confirmButtonRef?: MutableRefObject<null>;
} & OverlayTriggerProps;

export const SimplePopup = ({
    label,
    popupType,
    messageType,
    onClickCancel,
    onClickConfirm,
    onClickOk,
    isConfirmLoading,
    confirmText,
    cancelText,
    open,
    confirmButtonRef,
}: Props) => {
    const [isOpen, setIsOpen] = useState(open);
    const state = useOverlayTriggerState({ isOpen });

    const { overlayProps } = useOverlayTrigger({ type: "dialog" }, state);

    useEffect(() => setIsOpen(open), [open]);

    return (
        <>
            {isOpen && (
                <PopupOverlay messageType={messageType} state={state}>
                    <PopupDialog
                        title={label}
                        popupMessageType={messageType}
                        popupType={popupType}
                        setIsOpen={setIsOpen}
                        onClickConfirm={onClickConfirm}
                        onClickCancel={onClickCancel}
                        onClickOk={onClickOk}
                        isConfirmLoading={isConfirmLoading}
                        confirmText={confirmText}
                        cancelText={cancelText}
                        confirmButtonRef={confirmButtonRef}
                        {...overlayProps}
                    />
                </PopupOverlay>
            )}
        </>
    );
};
