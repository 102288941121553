import React, { RefObject } from "react";
import { useTranslation } from "react-i18next";

import { PopupMessageType, SimplePopup } from "@sol/uikit/molecules/SimplePopup/SimplePopup";

import { PopupType } from "./SimplePopup";

type ApiErrorAlertProps = {
    error: any;
    dissmiss: () => void;
    triggerElement?: RefObject<HTMLElement | undefined>;
};

const ApiErrorAlert = ({ error, dissmiss, triggerElement }: ApiErrorAlertProps) => {
    const [t] = useTranslation();
    const data = error?.response?.data || error?.data;
    const violations = data?.violations;
    const text: string = (((violations?.length || 0) > 0 && violations[0].message) ||
        data?.["hydra:description"] ||
        data?.message ||
        t("component.ApiErrorAlert.defaultText")) as string;

    const handleOkClick = () => {
        dissmiss();

        if (triggerElement) {
            triggerElement.current?.focus();
        }
    };

    return (
        <SimplePopup
            label={text}
            open={!!error}
            popupType={PopupType.ALERT}
            messageType={PopupMessageType.ERROR}
            isConfirmLoading={false}
            onClickOk={handleOkClick}
        />
    );
};

export default ApiErrorAlert;
