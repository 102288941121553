import React from "react";

import SvgIcon from "./SvgIcon";
import { IconBaseProps } from "./types";

const CircleCheck = ({ className }: IconBaseProps) => (
    <SvgIcon
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/SvgIcon"
        className={className}
    >
        <circle cx="12" cy="12" r="12" fill="white" className="colorFill" />
        <path
            d="M18 8L9.75 16L6 12.3636"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="colorStroke"
        />
    </SvgIcon>
);

export default CircleCheck;
