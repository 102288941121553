import React from "react";

import { IconBaseProps } from "./types";

const InfoIcon = ({ className }: IconBaseProps) => {
    return (
        <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            className={className}
        >
            <circle cx="6" cy="6" r="6" fill="#FFFFFF" className="colorFill" />
            <path d="M6 4L6 10" stroke="#000000" strokeLinecap="round" className="colorStroke" />
            <circle cx="6" cy="2" r="0.5" transform="rotate(180 6 2)" fill="#000000" className="colorStroke" />
        </svg>
    );
};

export default InfoIcon;
