import { OverlayTriggerState } from "@react-stately/overlays";
import React, { ReactNode, useRef } from "react";
import { AriaModalOverlayProps, Overlay, useModalOverlay } from "react-aria";

import { PopupMessageType } from "../SimplePopup";

type Props = {
    state: OverlayTriggerState;
    children: ReactNode;
    messageType: PopupMessageType;
} & AriaModalOverlayProps;

export const PopupOverlay = ({ state, children, messageType, ...props }: Props) => {
    const ref = useRef(null);
    const { modalProps, underlayProps } = useModalOverlay(props, state, ref);

    const boxShadowColor = messageType === PopupMessageType.INFO ? "shadow-purple-base" : "shadow-red-base";

    return (
        <Overlay>
            <div
                className="fixed inset-0 z-[9999] flex w-full items-center justify-center bg-[rgba(0,0,0,0.2)]"
                {...underlayProps}
            >
                <div
                    className={`box-border w-full max-w-[832px] shrink-0 rounded-lg bg-white-base shadow-[4px_4px_0px] ${boxShadowColor}`}
                    {...modalProps}
                    ref={ref}
                >
                    {children}
                </div>
            </div>
        </Overlay>
    );
};
