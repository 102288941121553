import { PaletteColorType } from "../theme/palette";

type StatusToColorType = {
    [key in InputStatus]: PaletteColorType;
};

export enum InputStatus {
    DEFAULT = "default",
    INFO = "info",
    SUCCESS = "success",
    ERROR = "error",
}

export const STATUS_TO_COLOR: StatusToColorType = {
    [InputStatus.DEFAULT]: "grey",
    [InputStatus.INFO]: "purple",
    [InputStatus.SUCCESS]: "green",
    [InputStatus.ERROR]: "red",
};

export const FOCUSED_STATUS_TO_COLOR: StatusToColorType = {
    ...STATUS_TO_COLOR,
    [InputStatus.DEFAULT]: "purple",
};
