import styled from "styled-components";

const InputTransparent = styled.input`
    ${({ theme }) => theme.typography.label}

    width: 100%;
    height: 24px;
    border: none;
    outline: none;
`;

export default InputTransparent;
