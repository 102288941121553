import { CircularProgress } from "@material-ui/core";
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "src/uikit";
import theme from "src/uikit/theme";
import styled, { css } from "styled-components";

import { Loader } from "@sol/icons";
import { Modal } from "@sol/modal";
import { AriaButton } from "@sol/uikit";
import Button from "@sol/uikit/buttons/Button";

import ModalContext from "../contexts/ModalContext";
import { InfoIcon } from "./icons";

export enum PopupMessageType {
    INFO = "INFO",
    ERROR = "ERROR",
}

export enum PopupType {
    CONFIRM = "CONFIRM",
    ALERT = "ALERT",
}

const MESSAGE_TYPE_COLOR = {
    [PopupMessageType.INFO]: theme.palette.purple.base,
    [PopupMessageType.ERROR]: theme.palette.red.base,
};

const LOADER_SIZE = 32;

type StyleModalProps = {
    messageType: PopupMessageType;
    loading: boolean;
};

const StyledModal = styled(Modal)<StyleModalProps>`
    padding: ${({ theme }) => theme.spacing[7]};
    background: ${({ theme }) => theme.palette.white.base};
    box-shadow: ${({ messageType }) => `4px 4px 0px ${MESSAGE_TYPE_COLOR[messageType]}`};
    border-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    ${({ loading }) =>
        loading
            ? css`
                  pointer-events: none;
              `
            : null}

    ${Loader} {
        height: ${LOADER_SIZE}px;
        width: ${LOADER_SIZE}px;
        margin-top: -${(LOADER_SIZE - 24) / 2}px;
        margin-bottom: -${(LOADER_SIZE - 24) / 2}px;
    }
`;

export const StyledCircularProgress = styled(CircularProgress)`
    margin: auto;
    margin-left: ${({ theme }) => theme.spacing[4]};
    height: 20px !important;
    width: 20px !important;
    color: ${({ theme }) => theme.palette.white.base} !important;
`;

const WrapperButton = styled.div`
    display: flex;
    justify-content: flex-end;

    button + button {
        margin-left: ${({ theme }) => theme.spacing[7]};
    }
`;

const TextIcon = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing[7]};
`;

const ErrorMessage = styled(Text)`
    font-weight: 900;
    color: ${({ theme }) => theme.palette.black.base};
    padding-right: ${({ theme }) => theme.spacing[10]};
    max-width: 618px;
    word-wrap: break-word;
    margin: ${({ theme }) => `0 0 0 ${theme.spacing[5]}`};
`;

const WrapperIcon = styled.div`
    height: 48px;
    width: 48px;
`;

// DEBT remove-inline-typing
const StyledInfoIcon = styled(InfoIcon)<Partial<PopupProps> & { color: string }>`
    transition: all ease 0.2s;
    transform: ${({ messageType }) => `rotate(${messageType === PopupMessageType.INFO ? "180deg" : "0"})`};
    > circle {
        stroke: none;
        fill: ${({ color }) => color};
    }
    > rect {
        fill: ${({ color }) => color};
    }
`;

export type PopupProps = {
    text: string;
    cancelText?: string;
    confirmText?: string;
    onClickCancel?: () => void;
    onClickConfirm?: () => void;
    onClickOk?: () => void;
    visible: boolean;
    type: PopupType;
    messageType: PopupMessageType;
    isConfirmLoading?: boolean;
};

const SimplePopup = ({
    text,
    cancelText,
    confirmText,
    onClickCancel,
    onClickConfirm,
    onClickOk,
    visible,
    type = PopupType.CONFIRM,
    messageType = PopupMessageType.INFO,
    isConfirmLoading,
}: PopupProps) => {
    const modalContext = useContext(ModalContext);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [t] = useTranslation();

    useEffect(() => {
        if (buttonRef && buttonRef.current && visible) {
            // We use the setTimeout to make sure everything is loaded and the focus is working
            setTimeout(() => {
                buttonRef?.current?.focus();
            }, 100);
        }
    }, [buttonRef, visible]);

    useEffect(() => {
        if (visible) {
            return modalContext.register();
        }
    }, [visible]);

    return (
        <StyledModal
            loading={!!isConfirmLoading}
            isOpen={visible}
            messageType={messageType}
            data-cy={`popup-${messageType}`}
        >
            <>
                <TextIcon>
                    <WrapperIcon>
                        <StyledInfoIcon
                            color={MESSAGE_TYPE_COLOR[messageType]}
                            messageType={messageType}
                            aria-hidden="true"
                        />
                    </WrapperIcon>
                    <ErrorMessage variant="h3" id="text">
                        {text}
                    </ErrorMessage>
                </TextIcon>
                <WrapperButton>
                    {type === PopupType.CONFIRM && (
                        <>
                            <Button outlined variant="primary" onClick={onClickCancel} ref={buttonRef} data-cy="cancel">
                                {cancelText ? cancelText : t("component.popup.cancel")}
                            </Button>
                            <Button variant="primary" filled onClick={onClickConfirm} data-cy="confirm">
                                {confirmText ? confirmText : t("component.popup.confirm")}
                                {!!isConfirmLoading && <Loader />}
                            </Button>
                        </>
                    )}
                    {type === PopupType.ALERT && (
                        <Button as={AriaButton} outlined variant="primary" onClick={onClickOk} data-cy="confirm">
                            {t("component.popup.ok")}
                        </Button>
                    )}
                </WrapperButton>
            </>
        </StyledModal>
    );
};

export default SimplePopup;
