import React, { Dispatch, SetStateAction } from "react";
import { AriaDialogProps, useDialog } from "react-aria";
import { useTranslation } from "react-i18next";
import { Button } from "src/modules/uikit/molecules/Button";

import { Loader, Info } from "@sol/uikit/core/icons";
import { Typography } from "@sol/uikit/core/Typography";
import { PopupMessageType, PopupType, type SimplePopupProps } from "@sol/uikit/molecules/SimplePopup";

type DialogProps = {
    title: string;
    popupMessageType: PopupMessageType;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
} & Omit<SimplePopupProps, "label" | "open" | "messageType"> &
    AriaDialogProps;

export const PopupDialog = ({
    title,
    popupMessageType,
    popupType,
    onClickConfirm,
    onClickCancel,
    onClickOk,
    setIsOpen,
    isConfirmLoading,
    confirmText,
    cancelText,
    confirmButtonRef,
    ...props
}: DialogProps) => {
    const [t] = useTranslation();
    const { ALERT } = PopupType;
    const ref = React.useRef(null);
    const { dialogProps, titleProps } = useDialog(props, ref);

    const iconRotate = popupMessageType === PopupMessageType.INFO ? "rotate-180" : "";
    const iconBgColor = popupMessageType === PopupMessageType.INFO ? "purple-light" : "red-light";

    const handleCancelClick = () => {
        if (onClickCancel) {
            onClickCancel();
        } else {
            setIsOpen(false);
        }
    };
    const handleConfirmClick = () => {
        if (onClickConfirm) {
            onClickConfirm();
        }
    };

    const handleOkClick = () => {
        if (onClickOk) {
            onClickOk();
        } else {
            setIsOpen(false);
        }
    };

    return (
        <div className="flex w-full flex-col gap-12 p-12" {...dialogProps} ref={ref}>
            <div className="flex items-center gap-4">
                <Info
                    bgColor={iconBgColor}
                    size={48}
                    fill="white-base"
                    stroke="white-base"
                    className={`${iconRotate}`}
                />
                <Typography tag="h3" variant="h3" className="max-w-[618px] !font-bold" {...titleProps}>
                    {title}
                </Typography>
            </div>
            <div className="flex items-center justify-end gap-5">
                {popupType === PopupType.CONFIRM && (
                    <>
                        <Button variant="secondary" onPress={handleCancelClick}>
                            {cancelText ? cancelText : t("component.popup.cancel")}
                        </Button>
                        <Button
                            forwardRef={confirmButtonRef}
                            variant="primary"
                            onPress={handleConfirmClick}
                            disabled={!!isConfirmLoading}
                        >
                            {confirmText ? confirmText : t("component.popup.confirm")}
                            {isConfirmLoading && <Loader stroke="white-base" fill="white-base" />}
                        </Button>
                    </>
                )}
                {popupType === ALERT && (
                    <Button variant="primary" onPress={handleOkClick}>
                        {t("component.popup.ok")}
                    </Button>
                )}
            </div>
        </div>
    );
};
