import React, { ReactNode, forwardRef, ComponentPropsWithRef } from "react";
import { Text } from "src/uikit";
import { Feedback, InputBase, Label } from "src/uikit/TextInput";
import { InputStatus } from "src/uikit/TextInput/utils";
import styled from "styled-components";

const InputPrefix = styled(Text)`
    display: flex;
    align-items: center;

    color: ${({ theme }) => theme.palette.grey.base};
    background-color: ${({ theme }) => theme.palette.grey.lightest};
    border: 1px solid ${({ theme }) => theme.palette.grey.base};
    border-right: none;
    border-top-left-radius: ${({ theme }) => theme.shape.borderRadius.medium};
    border-bottom-left-radius: ${({ theme }) => theme.shape.borderRadius.medium};

    margin: ${({ theme }) => theme.spacing[2]} 0;
    padding: ${({ theme }) => theme.spacing[4]};
`;

const InputWrapper = styled.div`
    display: flex;
    align-items: stretch;
`;

type Props = {
    label: string | ReactNode;
    prefix?: string;
    status?: InputStatus;
    feedback?: string;
    className?: string;
} & ComponentPropsWithRef<typeof InputBase>;

// eslint-disable-next-line react/display-name
const TextField = forwardRef<HTMLInputElement, Props>(
    ({ label, prefix, status = InputStatus.DEFAULT, feedback, className, ...inputProps }: Props, ref) => {
        return (
            <div className={className}>
                <Label htmlFor={inputProps.id}>{label}</Label>
                <InputWrapper>
                    {prefix && (
                        <InputPrefix as="span" aria-hidden="true">
                            {prefix}
                        </InputPrefix>
                    )}
                    <InputBase ref={ref} {...inputProps} status={status} aria-describedby={inputProps.name} />
                </InputWrapper>
                {!!feedback && (
                    <Feedback status={status} fieldName={inputProps.name}>
                        {feedback}
                    </Feedback>
                )}
            </div>
        );
    },
);

export default styled(TextField)<Props>`
    ${InputBase} {
        flex: 1 1 auto;
        height: 48px;
        min-width: 0;
        width: 1%;
        background: ${({ theme }) => theme.palette.white.base};
        ${({ prefix }) => prefix && `border-top-left-radius: 0; border-bottom-left-radius: 0;`}
    }
`;
