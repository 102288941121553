import { AnimatePresence, motion } from "framer-motion";
import React, { cloneElement, ReactElement, useRef } from "react";
import { AriaModalOverlayProps, Overlay, useModalOverlay, useOverlayTrigger, FocusScope } from "react-aria";
import { OverlayTriggerState, useOverlayTriggerState } from "react-stately";
import styled, { useTheme } from "styled-components";

const ModalUnderlay = styled(motion.div)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: ${({ theme }) => theme.spacing[4]};
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(var(--blur, 45px));
`;

const variants = {
    visible: {
        opacity: 1,
        background: "rgba(0, 0, 0, 0.2)",
    },
    hidden: {
        opacity: 0,
        background: "rgba(0, 0, 0, 0)",
    },
};

const childrenVariants = {
    visible: {
        opacity: 1,
        scale: 1,
    },
    hidden: {
        opacity: 0,
        scale: 0.95,
    },
};

const Wrapper = styled(motion.div)`
    max-width: 1256px;
    max-height: 100%;
    overflow: auto;
`;

type Props = {
    className?: string;
    children: ReactElement;
} & Partial<OverlayTriggerState> &
    AriaModalOverlayProps;

const Modal = ({ className, children, ...props }: Props) => {
    const ref = useRef(null);
    const theme = useTheme();
    const state = useOverlayTriggerState(props);
    const { overlayProps } = useOverlayTrigger({ type: "dialog" }, state);
    const { modalProps, underlayProps } = useModalOverlay(props, state, ref);

    return (
        <AnimatePresence>
            {state.isOpen && (
                <Overlay>
                    <ModalUnderlay
                        {...(underlayProps as any)}
                        variants={variants}
                        transition={theme.transitions.spring.default}
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                    >
                        <Wrapper
                            {...(modalProps as any)}
                            ref={ref}
                            variants={childrenVariants}
                            transition={theme.transitions.spring.default}
                            className={className}
                            role="dialog"
                            {...props}
                        >
                            <FocusScope contain restoreFocus autoFocus>
                                {cloneElement(children, overlayProps)}
                            </FocusScope>
                        </Wrapper>
                    </ModalUnderlay>
                </Overlay>
            )}
        </AnimatePresence>
    );
};

export default styled(Modal)``;
