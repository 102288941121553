import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Cross, Info, Loading, SvgIcon } from "@sol/icons";
import { Button } from "@sol/uikit";

import { Text } from "../../uikit";
import Modal from "./Modal";

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: ${({ theme }) => theme.spacing[6]};
    margin-top: ${({ theme }) => theme.spacing[10]};
`;

const Body = styled.div`
    --icon-color: var(--intent-color);
    --icon-bg-color: var(--intent-light-color);

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => theme.spacing[4]};

    > ${SvgIcon} {
        float: left;
    }
`;

type Props = {
    className?: string;
    isOpen?: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    text?: ReactNode;
    confirmLabel?: ReactNode;
    cancelLabel?: ReactNode;
    isLoading?: boolean;
    intent: "info" | "danger";
};
const ConfirmationDialog: FC<Props> = ({
    className,
    isOpen,
    onCancel,
    onConfirm,
    text,
    isLoading,
    confirmLabel,
    cancelLabel,
    intent = "info",
}) => {
    const [t] = useTranslation();

    return (
        <Modal isOpen={isOpen} className={className}>
            <>
                <Body>
                    {intent === "info" ? <Info size={48} circle /> : null}
                    {intent === "danger" ? <Cross size={48} circle /> : null}
                    <Text variant="h3" preserve>
                        {text}
                    </Text>
                </Body>
                <Actions>
                    <Button variant={intent === "info" ? "primary" : "danger"} onClick={onCancel} outlined>
                        {cancelLabel ?? t("@sol.modal.ConfirmationDialog.cancel")}
                    </Button>
                    <Button
                        variant={intent === "info" ? "primary" : "danger"}
                        onClick={onConfirm}
                        loading={isLoading}
                        filled
                    >
                        {confirmLabel ?? t("@sol.modal.ConfirmationDialog.confirm")}
                        {isLoading && <Loading />}
                    </Button>
                </Actions>
            </>
        </Modal>
    );
};

export default styled(ConfirmationDialog)`
    width: auto;
    min-width: 750px;
    --intent-color: ${({ theme, intent = "info" }) => {
        switch (intent) {
            case "danger":
                return theme.palette.red.base;
            case "info":
                return theme.palette.purple.base;
        }
    }};
    --intent-light-color: ${({ theme, intent = "info" }) => {
        switch (intent) {
            case "danger":
                return theme.palette.red.lightest;
            case "info":
                return theme.palette.purple.lighter;
        }
    }};

    background: ${({ theme }) => theme.palette.white.base};
    box-shadow: 4px 4px 0 var(--intent-color);
    border-radius: ${({ theme }) => theme.shape.borderRadius.large};

    display: flex;
    flex-direction: column;
    padding: ${({ theme }) => theme.spacing[9]};
`;
